import React from 'react';
import PropTypes from 'prop-types';
import { getImgixUrl } from '../../../../../../helpers';
import { CallToAction } from '../../../sharedComponents';
import { CarouselSecondItemTop } from '../CarouselSecondItemTop';
import { CarouselSecondItemBottom } from '../CarouselSecondItemBottom';
import {
  CarouselSecondContainer,
  CarouselSecondLink,
  CarouselSecondItemWrapper,
} from '../../styles';
import { useBuildSiteUrl } from '../../../../../../hooks/useBuildSiteUrl';

export const CarouselSecondItem = ({ event, tenantId, isEven }) => {
  const {
    localisedName,
    formattedDate,
    backgroundImage: backgroundImageObject,
    valueProposition,
    tenant,
    domain,
  } = event;

  const url = useBuildSiteUrl(event);

  const backgroundImage = getImgixUrl(
    tenantId,
    backgroundImageObject && backgroundImageObject.path,
    'w=1024&h=1024',
  );

  return (
    <CarouselSecondItemWrapper data-testid="related-events-carousel-second-item">
      <CarouselSecondContainer isEven={isEven}>
        <CarouselSecondLink>
          <CallToAction
            type="link"
            tenantId={tenant || tenantId}
            portfolioId={domain}
            to={url}
          >
            <CarouselSecondItemTop
              backgroundImage={backgroundImage}
              name={localisedName}
              formattedDate={formattedDate}
            />
            <CarouselSecondItemBottom
              isEven={isEven}
              valueProposition={valueProposition}
            />
          </CallToAction>
        </CarouselSecondLink>
      </CarouselSecondContainer>
    </CarouselSecondItemWrapper>
  );
};

CarouselSecondItem.propTypes = {
  event: PropTypes.shape({
    path: PropTypes.string,
    localisedName: PropTypes.string,
    link: PropTypes.shape({
      type: PropTypes.string,
      customUrl: PropTypes.string,
    }),
    formattedDate: PropTypes.string,
    backgroundImage: PropTypes.shape({
      path: PropTypes.string,
    }),
    valueProposition: PropTypes.string,
    tenant: PropTypes.string,
    domain: PropTypes.string,
  }),
  tenantId: PropTypes.string,
  isEven: PropTypes.bool,
};
