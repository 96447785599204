import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { getImgixUrl, renderHTMLString } from '../../../../../../helpers';
import SiteBrandService from '../../../../../../services/SiteBrandService';
import { CallToAction, ImageTag } from '../../../sharedComponents';
import { BlockWrapper, Date, Gradient, ImageWrapper } from '../../styles';
import { useBuildSiteUrl } from '../../../../../../hooks/useBuildSiteUrl';

export const RelatedEventsBlock = (props) => {
  const {
    event: {
      localisedName,
      formattedDate,
      backgroundImage: backgroundImageObject,
      brand,
      domain,
      tenant,
    },
    tenantId,
  } = props;

  const url = useBuildSiteUrl(props.event);

  const themeConfig = useContext(ThemeContext);
  const vStyles = themeConfig.module.vStyles;
  const dStyles = themeConfig.module.dStyles;

  const eventBrand = new SiteBrandService(brand);

  const gradientColor = backgroundImageObject
    ? eventBrand.getRGBAColor('primary', 0.6)
    : eventBrand.primaryColor;

  const logoPath = getImgixUrl(
    tenantId,
    eventBrand.getInvertedLogoPath(),
    'w=370&h=80',
  );

  const backgroundImage = getImgixUrl(
    tenantId,
    backgroundImageObject && backgroundImageObject.path,
    'w=780&h=780',
  );

  return (
    <BlockWrapper
      className="lazy"
      data-background-image={backgroundImage}
      data-testid="related-events-block"
    >
      <CallToAction
        type="link"
        tenantId={tenant || tenantId}
        portfolioId={domain}
        displayStyles={dStyles}
        visualStyles={vStyles}
        to={url}
      >
        <Gradient
          gradientColor={gradientColor}
          data-testid="related-events-block-gradient"
          displayStyles={dStyles}
          visualStyles={vStyles}
        />
        <ImageWrapper>
          {logoPath ? (
            <ImageTag
              imageConfig={{ path: logoPath }}
              lazy
              imgix={false}
              alt={localisedName}
              displayStyles={dStyles}
              visualStyles={vStyles}
              testId="related-events-block-image"
            />
          ) : (
            localisedName
          )}
        </ImageWrapper>
        <Date>{renderHTMLString(formattedDate)}</Date>
      </CallToAction>
    </BlockWrapper>
  );
};

RelatedEventsBlock.propTypes = {
  event: PropTypes.shape({
    path: PropTypes.string,
    link: PropTypes.shape({
      type: PropTypes.string,
      customUrl: PropTypes.string,
    }),
    localisedName: PropTypes.string,
    formattedDate: PropTypes.string,
    backgroundImage: PropTypes.object,
    brand: PropTypes.object,
    tenant: PropTypes.string,
  }),
  tenantId: PropTypes.string,
};
